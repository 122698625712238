import React, { useRef } from 'react';

// chakra
import { Container, Flex } from '@chakra-ui/react';

// components
import HeroSection from '../Landing/HeroSection';
import BenefitsSection from '../Landing/BenefitsSection/BenefitsSection';
import MessageSection from '../Landing/MessageSection';
import FeaturesSection from '../Landing/FeaturesSection/FeaturesSection';

export const Main = props => {
  const contactUsRef = useRef(null);

  return (
    <Flex as="main" role="main" direction="column" flex="1" {...props}>
      <HeroSection contactUsRef={contactUsRef} />
      <Container flex="1">
        <FeaturesSection />
        <BenefitsSection />
        <MessageSection contactUsRef={contactUsRef} />
      </Container>
    </Flex>
  );
};
