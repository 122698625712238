import React, { Fragment } from 'react';

// components
import { Logo } from '../common/LogoBlack';
import { ResourcesPopover } from './ResourcesPopover';
import SignUpModal from '../SignUpModal';

// chakra
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  HStack,
  IconButton,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  Slide,
  CloseButton,
} from '@chakra-ui/react';
import { FiMenu } from 'react-icons/fi';

export const Navbar = () => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: menuIsOpen, onToggle: menuOnToggle } = useDisclosure();

  return (
    <Fragment>
      <Box as="section">
        <Box as="nav" bg="bg-surface" boxShadow="sm">
          <Container
            py={{
              base: '4',
              lg: '5',
            }}
          >
            <HStack spacing="10" justify="space-between">
              <Logo />
              {isDesktop ? (
                <>
                  {/* <ButtonGroup variant="link" spacing="8">
                    <Button>Product</Button>
                    <Button>Pricing</Button>
                    <ResourcesPopover />
                    <Button>Support</Button>
                  </ButtonGroup> */}
                  <HStack spacing="3">
                    <Button
                      variant="ghost"
                      onClick={() => {
                        window.location.replace(
                          'https://app.housebauen.com/login'
                        );
                      }}
                    >
                      Log In
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => {
                        window.location.replace(
                          'https://app.housebauen.com/signup/investor'
                        );
                      }}
                    >
                      Sign Up
                    </Button>
                  </HStack>
                </>
              ) : (
                <>
                  <IconButton
                    variant="ghost"
                    icon={<FiMenu fontSize="1.25rem" />}
                    aria-label="Open Menu"
                    onClick={menuOnToggle}
                  />
                </>
              )}
            </HStack>
          </Container>
        </Box>
      </Box>

      <Slide direction="right" in={menuIsOpen} style={{ zIndex: 10 }}>
        <Box p="40px" bg="white" rounded="md" shadow="md" h="100vh">
          <Box>
            <Box>
              <CloseButton size="lg" onClick={menuOnToggle} />
            </Box>
            <Stack mt="5" spacing="4">
              <Button
                variant="ghost"
                size="lg"
                onClick={() => {
                  window.location.replace('https://app.housebauen.com/login');
                }}
              >
                Log In
              </Button>
              <Button
                variant="primary"
                size="lg"
                onClick={() => {
                  window.location.replace(
                    'https://app.housebauen.com/signup/investor'
                  );
                }}
              >
                Sign Up
              </Button>
            </Stack>
          </Box>
        </Box>
      </Slide>

      <SignUpModal isOpen={isOpen} onClose={onClose} />
    </Fragment>
  );
};
