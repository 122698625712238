import { BsCart4 } from 'react-icons/bs';
import { IoStatsChartOutline, IoStorefrontOutline } from 'react-icons/io5';
const features = [
  'Unlimited projects',
  'Lifetime access',
  'Customer support',
  'Free updates',
];

export const products = [
  {
    name: 'Developer',
    description: 'What we manage',
    features: [
      'Prequalification',
      'Estimating',
      'Project Management',
      'Quality Control',
      'Design Coordination',
      'Workforce Planning',
      'Field Productivity',
      'Project Financials',
      'Invoice Management',
      'Realtime Project Tracking',
      'Permit Submittals',
      'Complete source of truth',
    ],
  },
  {
    name: 'Contractor',
    description: 'How we help',
    features: [
      'Costs nothing to sign up',
      'We cover all material costs',
      'We pay the moment you finish the job',
      'License not needed for most jobs',
      'Access to world class management software',
      'Direct digital communication with engineers',
      'We handle the business so you can focus on construction',
    ],
  },
  {
    name: 'Investor',
    description: 'What we offer',
    features: [
      'Complete research on specific areas',
      'Multiple exit strategies',
      'GP/LP agreement pairing and turn key new rentals',
      'Full digital construction transparency',
      'Photos of the project at every phase of construction/inspection',
      'Access to live timelapse video footage',
      'Quick agreements, llc formation, and execution',
      'Simple waterfall models',
    ],
  },
];
