import React, { Fragment } from 'react';

// components
import SignUpModal from '../SignUpModal';

// chakra
import {
  Badge,
  Box,
  Button,
  Container,
  Heading,
  Img,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { HiPlay } from 'react-icons/hi';

const HeroSection = ({ contactUsRef }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Fragment>
      <Box as="section" bg="bg-surface">
        <Box position="relative" height={{ lg: '720px' }}>
          <Container py={{ base: '16', md: '24' }} height="full">
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              spacing={{ base: '16' }}
              align={{ lg: 'center' }}
              height="full"
            >
              <Stack spacing={{ base: '8', md: '12' }}>
                <Stack spacing="4">
                  <Stack
                    spacing={{ base: '4', md: '6' }}
                    maxW={{ md: 'xl', lg: 'md', xl: 'xl' }}
                  >
                    <Heading
                      size={{ base: 'md', md: 'xl' }}
                      textAlign={{ base: 'center', md: 'left' }}
                    >
                      Click a button, <br />
                      build a house.
                    </Heading>
                    <Text
                      fontSize={{ base: 'lg', md: 'xl' }}
                      color="muted"
                      textAlign={{ base: 'center', md: 'left' }}
                    >
                      We specialize in automating the general contractor duties
                      and planning so you can click a button and build a house.
                    </Text>
                  </Stack>
                </Stack>
                <Stack direction={{ base: 'column', md: 'row' }} spacing="3">
                  <Button
                    variant="primary"
                    size={{ base: 'lg', md: 'xl' }}
                    onClick={() => {
                      window.location.replace(
                        'https://app.housebauen.com/signup/investor'
                      );
                    }}
                  >
                    Sign Up
                  </Button>
                  <Button
                    variant="secondary"
                    size={{ base: 'lg', md: 'xl' }}
                    onClick={() => contactUsRef.current.scrollIntoView()}
                  >
                    Contact Us
                  </Button>
                </Stack>
              </Stack>
              <Box
                pos={{ lg: 'absolute' }}
                right="0"
                bottom="0"
                w={{ base: 'full', lg: '50%' }}
                height={{ base: '96', lg: 'full' }}
                sx={{
                  clipPath: {
                    lg: 'polygon(7% 0%, 100% 0%, 100% 100%, 0% 100%)',
                  },
                }}
              >
                <Img
                  boxSize="full"
                  objectFit="cover"
                  src="https://i.imgur.com/MxUEuBC.jpg"
                  alt="Housebauen Hero"
                  rounded={{ base: 'xl', lg: 'none' }}
                />
              </Box>
            </Stack>
          </Container>
        </Box>
      </Box>

      <SignUpModal isOpen={isOpen} onClose={onClose} />
    </Fragment>
  );
};

export default HeroSection;
