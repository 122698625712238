import React, { Fragment } from 'react';
import { products } from './data';

// components
import { PricingCard } from './PricingCard';

// chakra
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react';

const BenefitsSection = () => {
  return (
    <Fragment>
      <Box as="section">
        <Container py={{ base: '16', md: '24' }}>
          <Stack spacing={{ base: '16', md: '24' }}>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              columnGap="8"
              rowGap="6"
            >
              {products.map((product, id) => (
                <PricingCard key={id} product={product} />
              ))}
            </SimpleGrid>
          </Stack>
        </Container>
      </Box>
    </Fragment>
  );
};

export default BenefitsSection;
