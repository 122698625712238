import React, { Fragment, useState } from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

// chakra
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  HStack,
  IconButton,
  useBreakpointValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react';

const SignUpModal = ({ isOpen, onClose }) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Formik
          initialValues={{ name: '', email: '', occupation: '' }}
          validationSchema={yup.object({
            name: yup.string().required('Required'),
            email: yup.string().required('Required'),
            occupation: yup.string().required('Required'),
          })}
          onSubmit={async values => {
            try {
              setLoading(true);

              await axios.post(
                'https://housebauen-server.onrender.com/zapier/waitlist_application',
                {
                  name: values.name,
                  email: values.email,
                  occupation: values.occupation,
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              );

              toast({
                title: 'Waitlist Application Sent',
                description:
                  'We will try and get back to you as soon as possible.',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top-right',
              });

              values.name = '';
              values.email = '';
              values.occupation = '';

              setLoading(false);
              onClose();
            } catch (err) {
              console.log('Error onSubmit(): ', err);

              toast({
                title: 'Something went wrong',
                description: 'Try to send the application again',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right',
              });

              values.name = '';
              values.email = '';
              values.occupation = '';

              setLoading(false);
            }
          }}
        >
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Join Our Waitlist!</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Stack spacing="8">
                    <Text>
                      We are currently in the process of onboarding our first
                      few customers. If you are interested then please provide
                      your name and email to get on the waitlist.
                    </Text>

                    <Stack spacing="4">
                      <FormControl
                        isInvalid={formik.errors.name && formik.touched.name}
                        isDisabled={loading}
                      >
                        <Field
                          as={Input}
                          type="text"
                          size="lg"
                          placeholder="Enter your Name"
                          id="name"
                          name="name"
                        />
                        {formik.errors.name && formik.touched.name && (
                          <FormErrorMessage>
                            {formik.errors.name}
                          </FormErrorMessage>
                        )}
                      </FormControl>

                      <FormControl
                        isInvalid={formik.errors.email && formik.touched.email}
                        isDisabled={loading}
                      >
                        <Field
                          as={Input}
                          type="email"
                          size="lg"
                          placeholder="Enter your Email"
                          id="email"
                          name="email"
                        />
                        {formik.errors.email && formik.touched.email && (
                          <FormErrorMessage>
                            {formik.errors.email}
                          </FormErrorMessage>
                        )}
                      </FormControl>

                      <FormControl
                        isInvalid={
                          formik.errors.occupation && formik.touched.occupation
                        }
                        isDisabled={loading}
                      >
                        <Field
                          as={Select}
                          id="occupation"
                          name="occupation"
                          placeholder="Select an Occupation"
                        >
                          <option key="investor" value="investor">
                            Investor
                          </option>
                          <option key="developer" value="developer">
                            Developer
                          </option>
                          <option key="contractor" value="contractor">
                            Contractor
                          </option>
                        </Field>
                        {formik.errors.occupation &&
                          formik.touched.occupation && (
                            <FormErrorMessage>
                              {formik.errors.occupation}
                            </FormErrorMessage>
                          )}
                      </FormControl>
                    </Stack>
                  </Stack>
                </ModalBody>

                <ModalFooter>
                  <Button variant="primary" type="submit" isLoading={loading}>
                    Join Waitlist
                  </Button>
                </ModalFooter>
              </ModalContent>
            </form>
          )}
        </Formik>
      </Modal>
    </Fragment>
  );
};

export default SignUpModal;
