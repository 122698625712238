import React, { Fragment, useState } from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import axios from 'axios';

// chakra
import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  Heading,
  Input,
  Stack,
  Text,
  Textarea,
  FormErrorMessage,
  useToast,
} from '@chakra-ui/react';

const MessageSection = ({ contactUsRef }) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  return (
    <Fragment>
      <Formik
        initialValues={{ name: '', email: '', message: '' }}
        validationSchema={yup.object({
          name: yup.string().required('Required'),
          email: yup.string().required('Required'),
          message: yup.string().required('Required'),
        })}
        onSubmit={async values => {
          try {
            setLoading(true);

            await axios.post(
              'https://housebauen-server.onrender.com/zapier/contact_us_request',
              {
                name: values.name,
                email: values.email,
                message: values.message,
              },
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            );

            toast({
              title: 'Message Sent',
              description:
                'We will try and get back to you as soon as possible.',
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'top-right',
            });

            values.name = '';
            values.email = '';
            values.message = '';

            setLoading(false);
          } catch (err) {
            console.log('Error onSubmit(): ', err);

            toast({
              title: 'Something went wrong',
              description: 'Try to send the message again',
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'top-right',
            });

            values.name = '';
            values.email = '';
            values.message = '';

            setLoading(false);
          }
        }}
      >
        {formik => (
          <form onSubmit={formik.handleSubmit}>
            <Container py={{ base: '16', md: '24' }} ref={contactUsRef}>
              <Stack
                spacing={{ base: '8', md: '10' }}
                align="center"
                maxW="3xl"
                mx="auto"
              >
                <Stack spacing={{ base: '4', md: '5' }} textAlign="center">
                  <Heading size={{ base: 'sm', md: 'md' }}>
                    Want to work with us?
                  </Heading>
                  <Text fontSize={{ base: 'lg', md: 'xl' }} color="muted">
                    Your project is very important to us.
                  </Text>
                </Stack>

                <Stack
                  direction="column"
                  width="full"
                  maxW={{ md: 'lg' }}
                  spacing="4"
                >
                  <FormControl
                    flex="1"
                    isInvalid={formik.errors.name && formik.touched.name}
                    isDisabled={loading}
                  >
                    <Field
                      as={Input}
                      type="text"
                      size="lg"
                      placeholder="Name"
                      id="name"
                      name="name"
                    />
                    {formik.errors.name && formik.touched.name && (
                      <FormErrorMessage>{formik.errors.name}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    flex="1"
                    isInvalid={formik.errors.email && formik.touched.email}
                    isDisabled={loading}
                  >
                    <Field
                      as={Input}
                      type="email"
                      size="lg"
                      placeholder="Email"
                      id="email"
                      name="email"
                    />
                    {formik.errors.email && formik.touched.email && (
                      <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl
                    flex="1"
                    isInvalid={formik.errors.message && formik.touched.message}
                    isDisabled={loading}
                  >
                    <Field
                      as={Textarea}
                      placeholder="Type your message"
                      id="message"
                      name="message"
                    />
                    {formik.errors.message && formik.touched.message && (
                      <FormErrorMessage>
                        {formik.errors.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <Button
                    variant="primary"
                    size="lg"
                    type="submit"
                    isLoading={loading}
                  >
                    Submit
                  </Button>
                </Stack>
              </Stack>
            </Container>
          </form>
        )}
      </Formik>
    </Fragment>
  );
};

export default MessageSection;
