import { Box, Container } from '@chakra-ui/react';
import { Placeholder } from './Placeholder';

// components
import { Footer as FooterComponent } from '../Footer/Footer';

export const Footer = props => {
  return (
    <Box as="footer" role="contentinfo" bg="bg-accent" {...props}>
      <Container>
        <FooterComponent />
      </Container>
    </Box>
  );
};
