import {
  Box,
  Button,
  Circle,
  Heading,
  HStack,
  Icon,
  List,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { FiCheck } from 'react-icons/fi';

export const PricingCard = props => {
  const { product } = props;
  return (
    <Box
      bg="bg-surface"
      borderRadius="2xl"
      boxShadow="lg"
      px={{
        base: '6',
        md: '8',
      }}
      py="8"
    >
      <Stack spacing="8" textAlign="center">
        <Stack spacing="5" align="center">
          <Stack>
            <Heading
              size={{
                base: 'md',
                md: 'lg',
              }}
            >
              {product.name}
            </Heading>
            <Text color="muted" fontWeight="600">
              {product.description}
            </Text>
          </Stack>
        </Stack>
        <List as="ul" spacing="4">
          {product.features.map(feature => (
            <HStack key={feature} as="li" spacing="3">
              <Circle size="6" bg={mode('blue.50', 'whiteAlpha.50')}>
                <Icon as={FiCheck} color="accent" />
              </Circle>
              <Text color="muted" align="left">
                {feature}
              </Text>
            </HStack>
          ))}
        </List>
        {/* <Button variant="primary" size="lg">
          Learn More
        </Button> */}
      </Stack>
    </Box>
  );
};
