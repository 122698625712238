import React, { Fragment } from 'react';

// components
import { Navbar } from '../components/Layout/Navbar';
import { Main } from '../components/Layout/Main';
import { Footer } from '../components/Layout/Footer';

// chakra
import { Flex } from '@chakra-ui/react';

const HomePage = () => {
  return (
    <Fragment>
      <Flex direction="column" flex="1">
        <Navbar />
        <Main />
        <Footer />
      </Flex>
    </Fragment>
  );
};

export default HomePage;
