import React, { Fragment } from 'react';
import { features } from './data';

// chakra
import {
  Box,
  Container,
  Heading,
  Image,
  Stack,
  Text,
  Button,
  Icon,
  Square,
  useColorMode,
  Flex,
} from '@chakra-ui/react';
import { FiArrowRight } from 'react-icons/fi';

const FeaturesSection = () => {
  return (
    <Fragment>
      <Box as="section">
        <Box>
          <Container
            pt={{ base: '16', md: '24' }}
            pb={{ base: '28', md: '40' }}
          >
            <Stack spacing={{ base: '8', md: '10' }} align="center">
              <Stack
                spacing={{ base: '4', md: '5' }}
                textAlign="center"
                align="center"
              >
                <Stack spacing="3">
                  <Heading size={{ base: 'sm', md: 'md' }} maxW="4xl">
                    Building a house involves several steps and can be a complex
                    process.
                  </Heading>
                </Stack>
                <Text
                  fontSize={{ base: 'lg', md: 'xl' }}
                  maxW="4xl"
                  color="muted"
                >
                  This process can take several months to complete and will
                  require the work of several different contractors and
                  tradespeople, such as architects, builders, plumbers,
                  electricians, and more. It's important to have a clear and
                  detailed plan, a good project management, and a good
                  communication with the contractors to ensure that the project
                  is completed on time and on budget.
                </Text>
              </Stack>
              <Image
                src="https://res.cloudinary.com/dsfzcj5qk/image/upload/v1615474834/5-tips-tech-competative/construction-tech-for-small-businesses.jpg"
                objectFit="cover"
                w="100%"
                maxH="470px"
                rounded="xl"
              />
            </Stack>
          </Container>
        </Box>
        <Box>
          <Stack
            direction={{ base: 'column', md: 'row' }}
            spacing={{ base: '12', lg: '16' }}
          >
            <Stack
              spacing={{ base: '10', md: '12' }}
              maxW="xl"
              justify="center"
              width="full"
            >
              <Stack spacing="3">
                <Heading size={{ base: 'sm', md: 'md' }}>
                  Automating general contracting
                </Heading>
              </Stack>
              <Stack>
                <Text color="muted" fontSize={{ base: 'lg', md: 'xl' }}>
                  Automating general contracting involves using technology to
                  streamline and optimize various aspects of the construction
                  process, such as project management, scheduling, budgeting,
                  and communication. Some ways to automate general contracting
                  include:
                </Text>

                <Text color="muted" fontSize={{ base: 'lg', md: 'xl' }}>
                  Using project management software to keep track of tasks,
                  deadlines, and progress. This can help ensure that all team
                  members are on the same page and that the project stays on
                  schedule.
                </Text>

                <Text color="muted" fontSize={{ base: 'lg', md: 'xl' }}>
                  Implementing a scheduling system that allows for real-time
                  updates and changes. This can help prevent delays and ensure
                  that all team members are aware of the latest schedule.
                </Text>

                <Text color="muted" fontSize={{ base: 'lg', md: 'xl' }}>
                  Using budgeting software to keep track of expenses, invoices,
                  and payments. This can help ensure that the project stays
                  within budget and that all financial transactions are tracked
                  and recorded.
                </Text>

                <Text color="muted" fontSize={{ base: 'lg', md: 'xl' }}>
                  Utilizing digital tools for communication and collaboration,
                  such as instant messaging, video conferencing, and file
                  sharing. This can help keep team members connected and
                  facilitate quick decision making.
                </Text>
              </Stack>
            </Stack>
            <Flex width="full" overflow="hidden" align="center">
              <Box>
                <Image
                  maxW="100%"
                  minH={{ base: '100%', lg: '560px' }}
                  objectFit="cover"
                  src="https://i.imgur.com/o3x5QuJ.jpg"
                  alt="Developer"
                  rounded="xl"
                />
              </Box>
            </Flex>
          </Stack>
        </Box>
      </Box>
    </Fragment>
  );
};

export default FeaturesSection;
