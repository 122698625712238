import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// pages
import HomePage from './pages/HomePage';
import LogIn from './pages/LogInPage';

// components
import { Navbar } from './components/Layout/Navbar';
import { Main } from './components/Layout/Main';
import { Footer } from './components/Layout/Footer';

// chakra
import { Box, Text, Link, VStack, Code, Grid, Flex } from '@chakra-ui/react';

function App() {
  return (
    // <Flex direction="column" flex="1">
    //   <Navbar />
    //   <Main />
    //   <Footer />
    // </Flex>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<LogIn />} />
    </Routes>
  );
}

export default App;
