import { Box, Container } from '@chakra-ui/react';
import { useNavbar } from './useNavbar';

// components
import { Navbar as NavComponent } from '../Navbar/Navbar';

export const Navbar = () => {
  const { rootProps } = useNavbar();
  return (
    <Box
      as="nav"
      role="navigation"
      position="sticky"
      top="0"
      zIndex="docked"
      bg="bg-accent"
      {...rootProps}
    >
      <NavComponent />
    </Box>
  );
};
